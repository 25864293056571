import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.header-slider__carousel');

  if (carousel) {
    const args = {
      navigation: {
        prevEl: carousel.querySelector('.swiper-button-prev'),
        nextEl: carousel.querySelector('.swiper-button-next'),
      },
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        type: 'fraction',
      },
    };
    const slides = carousel.querySelectorAll('.swiper-slide');

    if (1 < slides.length) {
      args.loop = true;
      args.autoplay = true;
    }

    new Swiper(carousel, args);
  }
});
